/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "https://use.typekit.net/odz4tum.css";

@import url("https://use.typekit.net/odz4tum.css");

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    padding-top: 50px;
    background-color: white;
}

.mat-button-wrapper{
  display: none;
}
.mat-calendar-body-selected {
    background-color: #D0AF21;
    border-color: #D0AF21;
    color: #fff;
}

.mat-calendar-body-selected:hover {
  background-color: #D0AF21 !important;
  color: #fff;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled) {

  &.mat-calendar-body-active {
    &:focus {
      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected).mat-focus-indicator.mat-calendar-body-today {
        background-color: #D0AF21 !important;
      }
    }
  }
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border-color: #D0AF21;
}

.mat-step-text-label {
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "avory-i-latin";
}

.mat-horizontal-stepper-header-container {


}


ion-popover {
  --min-width: 137.469px; 

}

.modalsize--reset {
  --width: 550px;
  --height: 500px;
  border: 2px solid #BBB;
  --box-shadow: none;
  
}

ion-modal.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

@font-face {   
    font-family: avory-i-latin, sans-serif;
    src: url('https://use.typekit.net/odz4tum.css');
  }


  @font-face {   
    font-family: elza, sans-serif;
    src: url('https://use.typekit.net/odz4tum.css');
  }

  .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: #D0AF21;
    color: #fff;
}

.mat-step-header {
  padding-right: 34px !important;
}



@media(min-width: 1100px){

  .modalsize--reset .modal-wrapper.sc-ion-modal-md, .modal-shadow.sc-ion-modal-md{
      --width: 650px;
      --height: 600px;
      border: 2px solid #BBB;
      --box-shadow: none;
  }

}

@media screen and (max-width: 900px){

  .mat-stepper-horizontal, .mat-stepper-vertical {
    width: 100%;

  }
  .mat-step-text-label{
   font-size: 10px;
  }


  .mat-step-label {
    min-width: 25px  !important;
}

.mat-horizontal-content-container{
  padding: 0px !important;

}

}


